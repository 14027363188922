<template>
  <CreateProject class="keep-footer-off-page" />
</template>

<script>
import CreateProject from "@/components/CreateProject.vue";

export default {
  name: "new-project-view",

  components: { CreateProject },

  data() {
    return {
      project: "",
    };
  },
};
</script>
