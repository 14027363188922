<template>
  <section class="section">
    <div class="container">
      <div class="columns is-centered">
        <div class="column cardSize is-two-thirds box p-5">
          <!-- Main Content -->

          <!-- Card -->
          <!-- SmartTag -->
          <h1 class="pb-2 heading has-text-centered">New Project</h1>
          <div class="tag_box">
            <SmartTag class="py-3" :tags="mainTagInput" />
          </div>
          <hr />

          <ValidationObserver v-slot="{ handleSubmit }">
            <!-- Use form so enter to submit works -->
            <form
              @submit.prevent="handleSubmit(createProject)"
              novalidate="true"
            >
              <h1 class="heading has-text-centered mb-5">General</h1>

              <!-- Project name -->
              <ValidationProvider
                rules="required|name_min:5|name_max:20"
                name="projectValidator"
                v-slot="{ errors }"
              >
                <b-field
                  class="my-5"
                  label="Project Name"
                  label-position="on-border"
                  :type="{ 'is-info': errors[0] }"
                  :message="errors"
                >
                  <b-taginput
                    :has-counter="false"
                    v-model="project.name"
                    placeholder="..."
                    attached
                    maxlength="20"
                    maxtags="1"
                    type="is-project"
                  >
                  </b-taginput>
                </b-field>
              </ValidationProvider>

              <!-- Description -->
              <ValidationProvider
                rules="required"
                name="descriptionValidator"
                v-slot="{ errors }"
              >
                <b-field
                  class="my-5"
                  label="Description"
                  label-position="on-border"
                  :type="{ 'is-info': errors[0] }"
                  :message="errors"
                >
                  <b-input
                    type="textarea"
                    maxlength="500"
                    v-model="project.description"
                  ></b-input>
                </b-field>
              </ValidationProvider>
              <hr />

              <!-- Create button -->
              <div class="tag_box">
                <button
                  v-bind:class="{
                    'is-loading': isLoading,
                  }"
                  type="submit"
                  @click="activateObjectiveInputErrors()"
                  class="is-primary is-outlined mt-3 button"
                >
                  Create
                </button>
              </div>
            </form>
          </ValidationObserver>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import SmartTag from "@/components/SmartTag.vue";

import isEmpty from "lodash/isEmpty";
import {
  setInteractionMode,
  ValidationObserver,
  ValidationProvider,
} from "vee-validate";
import store from "../store";

export default {
  name: "CreateProject",

  components: {
    SmartTag,
    ValidationObserver,
    ValidationProvider,
  },

  created() {
    setInteractionMode("eager");
  },

  data() {
    return {
      isLoading: false,
      response: {},
      project: {
        name: [],
        deadline: null,
        description: "",
        setter: "UsernameGoesHere",
      },
    };
  },

  methods: {
    isEmpty,
    startLoadingAnimations() {
      this.isLoading = true;
    },
    stopLoadingAnimations() {
      this.isLoading = false;
    },
    async SendData() {
      this.startLoadingAnimations();
      const baseUrl = "https://solute-python3.onrender.com";
      const subdomain = "/project";
      const fullUrl = baseUrl + subdomain;

      const idToken = store.state.auth.user.accessToken;

      let config = {
        headers: {
          Authorization: `Bearer ${idToken}`,
        },
      };

      let data = {
        projectName: this.project.name[0],
        description: this.project.description,
      };

      try {
        const response = await this.$axios.post(fullUrl, data, config);
        this.response = response.data;
        console.log(this.response.msg);

        this.$buefy.toast.open("Project Created ");
        this.$router.push({ name: "Project", params: { pid: "testpid" } });
        this.stopLoadingAnimations();
      } catch (error) {
        if (error.response) {
          // Request made but the server responded with an error
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
          this.$buefy.toast.open({
            message: "Server Error",
            type: "is-danger",
          });
        } else if (error.request) {
          // Request made but no response is received from the server.
          console.log(error.request.msg);
          this.$buefy.toast.open({
            message: "Error : No Response",
            type: "is-danger",
          });
        } else {
          // Error occured while setting up the request
          console.log("Error", error.message);
          this.$buefy.toast.open({
            message: "Request Error",
            type: "is-danger",
          });
        }
      }
      this.stopLoadingAnimations();
    },
    createProject() {
      this.SendData();
    },

    activateObjectiveInputErrors() {
      this.objectiveInputActive = true;
      // Force reset of objective input field by changing key
      this.objectiveKey = !this.objectiveKey;
    },
  },
  computed: {
    mainTagInput() {
      return [{ type: "is-project", value: this.project.name[0] }];
    },
  },
};
</script>

<style scoped>
.tag_box {
  display: flex;
  justify-content: center;
}
.cardSize {
  max-width: 750px;
}
</style>
